import { Component, inject, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppService } from './services/app.service';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [
  
  ]
})
export class AppComponent {
  title = 'cbadmin';
  apps = inject(AppService);

  @HostListener('window:message', ['$event'])
  onMessage(event:any) {
    let ev = this.apps.bas.es.onMessage(event);
    
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?:any) {
    this.apps.bas.ui.onResize(event);
  }


}
