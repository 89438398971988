import { Component, Input, OnInit, Injector, EventEmitter, Output } from '@angular/core';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";
import { NzAlertComponent } from 'ng-zorro-antd/alert';


@Component({
  selector: 'bt-alert',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './bt-alert.component.html',
  styleUrl: './bt-alert.component.scss'
})
export class BtAlertComponent {
  bas = inject(BooktechAppService);

  @Input() nzType:  "success" | "info" | "warning" | "error" = 'info';
  @Input() btClass: string = "my-d";
  @Input() message: string = "";
  @Input() mk: string = "";
  @Input() description: string = "";

  @Input() opts: any = { };

  constructor() {

  }

  ngOnInit(): void {
 
    if (this.mk) this.message = this.bas.ui.actrans(this.mk);
  }

}


