
import { Component, Injector, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from '../../../util/misc.util';

 
import { DynformControl } from '../../../model/dymform-control';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";
import { BtSelectGuestsComponent } from '../select-guests/select-guests.component';
import { BtEvent } from '../../../services/event.service';


@Component({
  selector: 'btlib-pw-period-guests',
  standalone: true,
  imports: [ LibModulesWolcModule, BtSelectGuestsComponent ],
  templateUrl: './pw-period-guests.component.html',
  styleUrl: './pw-period-guests.component.scss'
})
export class BtPwPeriodGuestsComponent {
  bas = inject(BooktechAppService);

  // route:ActivatedRoute;

  views:any = {
    default: {
      grid: {
        date:     { xs: 24, sm: 24, md: 24, lg: 24, xl: 24,  xxl: 24,  gutter: 16 },
        guests:   { xs: 24, sm: 24, md: 24, lg: 24, xl: 24,  xxl: 24,  gutter: 16 },
      },
      size: "default"
    },
    fullWidth: {
      grid: {
        date:     { xs: 24, sm: 8, md: 8, lg: 8, xl: 8,  xxl: 8,  gutter: 16 },
        guests:   { xs: 24, sm: 8, md: 8, lg: 8, xl: 8,  xxl: 8,  gutter: 16 },
      },
      size: "large"
    }
  };
  view = this.views.default;

  
  _pw:any;
  @Input() 
  set pw(pw: any) {

    let curr = this._pw;
    this._pw = pw;
    if (pw) {
      if (!curr || curr.startDate != pw.startDate) {
        this.updateTimeGrid(pw);
      }
   
    }
  }
  get pw() { return this._pw; }
  // pw:any;

  @Input() opts:any = { };
  @Output() change = new EventEmitter<any>();

  options:any = {
    time: {

    },
    date: {

    },
    misc: {

    }
  }

  // rangeOptions:any = {
  //   inRangeInvalid: false
  // }
  
  
  dateInvalid:any[] = [];
  dateColors:any[] = [];

  pwInput:any = undefined;

  timegrid:any = {
    visible: false,
    label: 'app.cbapi5.common.selectTime',
    validStart: [
      // { start: '12:00', end: '13:59', recurring: { repeat: 'daily' } } // Trekke fra ett minutt på end
    ],
    validEnd: [
      // { start: '12:01', end: '14:00', recurring: { repeat: 'daily' } } // Legge til ett minutt på start

    ],

  }

  constructor() {
    // this.route = inject(ActivatedRoute);
    // this.bas.us.checkActivatedRoute(this.route.snapshot);

    // let qps = this.route.snapshot.queryParams; //periodAsRange
    // if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, qps: ", qps);



  }

  ngOnInit(): void {
    let opts = this.opts;
    if (opts.viewName) {
      this.view = this.views[opts.viewName];
    }

    if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, pw: ", this.pw);
    // this.postChange();

    if (this.bas.ds.findCid('pw-period-guests.ngOnInit') === "skaperverkettonsberg") { //TODO
      this.options.date = {
        inRangeInvalid: true,
        // rangeEndInvalid: false
      }
      
    }


    let pw = this.pw; 
    // let pwid = pw.id;
    this.updateTimeGrid(pw);

    this.bas.es.on(BtEvent.LibPwUpdate, (ev:any) => {
      if (this.pw.productId != ev.pw.productId) return; 
      
      if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, LibPwUpdate,  pw.id: " + this.pw.id +", ev: ", ev);
    
      // if (this.pw.id == ev.id) {
        if (this.pw.startDate != ev.pw.startDate) {
          if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, LibPwUpdate, startDate has changed: "+this.pw.startDate + " -> " + ev.pw.startDate +" ev: ", ev);
          // this.pw;
          this.updateTimeGrid(ev.pw);
        }
      // }
    })



    // let tg = this.timegrid;
    // if (tg.startTimeAsString == undefined) {
    //   tg.star = item.startTimeAsString;
    //   tg.end = item.endTimeAsString;
    //   tg.label = tg.startTimeAsString + " - " + tg.endTimeAsString;

      
    // }

  }


  timegridVisibleChange(event:any) {
    if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, Visible: " + event);


    let pw = this.pw;
    let tg = this.timegrid;

    if (!pw.productObj.useTimegrid) return; 

    if (event) {

      tg.lastEndItem = undefined;

      let startTime = this.bas.ui.timeFormat(tg.start);
      let endTime = this.bas.ui.timeFormat(tg.end);
    

      let ti = pw.timeItemsAvailable && pw.timeItemsAvailable[0];
      // if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, ti: ", ti);

      let validStart = [];
      let validEnd = [];

      // let list = [];


      let prev:any = false;
      let startItem:any = false;
      let notAvailable = false; 
      for (let item of ti.items || []) {

        // if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, item: ", item);


        if (!startItem && item.startTimeAsString == startTime) startItem = item;

        let endStart = this.bas.ui.dateParse(item.startAsString);
        // endStart = this.bas.ui.dateAdd(endStart, { minutes: +1 });
        let endStartAsString = this.bas.ui.timeFormat(endStart);

        item.startAsDate = endStart;

        let corrEnd = this.bas.ui.dateParse(item.endAsString);

        item.endAsDate = corrEnd;

        corrEnd = this.bas.ui.dateAdd(corrEnd, { minutes: -1 });
        let corrEndAsString = this.bas.ui.timeFormat(corrEnd);

        let isFirst = item.startAsString === ti.startAsString;
        let isLast = item.endAsString === ti.endAsString;


        if(!startTime || item.startTimeAsString > startTime) {
          item.endTimeAfterStart = true;
        }



        let isAvailable = item.available > 0;
        if (isAvailable) {
         
          validStart.push({ start: item.startTimeAsString, end: corrEndAsString, recurring: { repeat: 'daily' } });
          item.validStart = true;

          
          if (!notAvailable && ((startTime && item.startTimeAsString > startTime) || isLast)) {
            // if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, validEnd, startTime: "+startTime+", item: ", item);

            

            if(startTime) {
              if(item.startTimeAsString > startTime) {
                validEnd.push({ start: endStartAsString, end: corrEndAsString, recurring: { repeat: 'daily' } });
                // item.endValue = true;
                item.validEnd = true;
              } else {

              }
            } 


            if (isLast) {
              validEnd.push({ start: item.endAsString, end: item.endAsString, recurring: { repeat: 'daily' } });
              // item.validEnd = true;
              tg.lastEndItem = MiscUtil.clone(item);
              tg.lastEndItem.startTimeAsString = item.endTimeAsString;
            }
          } else {
            // if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, !validEnd, startTime: "+startTime+", item: ", item);
          }
       


  
        } else {
          if(startItem) notAvailable = true; 

          if (prev && prev.available > 0 && (startTime && item.startTimeAsString > startTime)) {
            if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, validEnd-prevAvil, startTime: "+startTime+", item: ", item, ", prev: ", prev);

            validEnd.push({ start: endStartAsString, end: corrEndAsString, recurring: { repeat: 'daily' } });
            item.validEnd = true;
          } else {

            // if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, !isAvailable, item: ", item);
          }
        }



        prev = item;
        // { start: '12:00', end: '13:59', recurring: { repeat: 'daily' } }// Trekke fra ett minutt på end
        // { start: '12:01', end: '14:00', recurring: { repeat: 'daily' } } // Legge til ett minutt på start
        /*
available: 1​
duration: 30​​​​​
durationAsString: "00:30"​​​​​
end: "22.05.24 10:30"​​​​​
endAsString: "2024-05-22 10:30"​​​​​
endTimeAsString: "10:30"​​​​​
hasChildAvailable: false​​​​​
id: 8000​​​​​
label: "10:00-10:30"​​​​​
productId: 3906​​​​​
start: "22.05.24 10:00"​​​​​
startAsString: "2024-05-22 10:00"​​​​​
startTimeAsString: "10:00"​​​​​
timePeriod: "10:00-10:30"​​​​​
timePeriodDisplay: "10:00-10:30"
        */
      }

      tg.items = [...ti.items];

      tg.validStart = validStart;
      tg.validEnd = validEnd;





      if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, tg: ", tg);
    } else {

    }


  }

  onTimegridChange(field:'start'|'end', event:any)  {
   
    let isStart = field == "start";

    let pw = this.pw;
    let ti = pw.timeItemsAvailable && pw.timeItemsAvailable[0];
    let tg = this.timegrid;

    if (!pw.productObj.useTimegrid) return; 

    if(this.bas.envtest) console.log("onTimegridChange"
      + ", field: "+field
      + ", tg.start: "+tg.start
      + ", tg.end: "+tg.end
      + ", tg.startTime: "+tg.startTime
      + ", tg.endTime: "+tg.endTime
      +", event: " + (event?.valueText || event) 
      + ", tg: ", this.timegrid);

    if (isStart && event?.valueText === undefined) {
      tg.start = this.bas.ui.timeParse(tg.startTime, undefined, tg.start);
    }
    if (!isStart && event?.valueText === undefined) {
      tg.end = this.bas.ui.timeParse(tg.endTime, undefined, tg.end);
    }

    let startTime = this.bas.ui.timeFormat(tg.start);
    let endTime = this.bas.ui.timeFormat(tg.end);


    if (startTime && endTime && endTime <= startTime) {
      tg.end = this.bas.ui.dateAdd(tg.start, { minutes: ti.duration || 30 })
      endTime = this.bas.ui.timeFormat(tg.end);

    }

    tg.label = startTime + " - " + endTime;
    tg.startTime = startTime;
    tg.endTime = endTime;


    setTimeout(() => { 
      
      this.timegridVisibleChange(true);
    }, 100)

    let timePeriodAsString = tg.label.replace(" ", "");
    if (pw.timePeriodAsString != timePeriodAsString) {

      pw.timePeriodAsString = timePeriodAsString;
      this.change.emit({ field: "timePeriodAsString", value:   pw.timePeriodAsString });
    } else {
      if(this.bas.envtest) console.log("onTimegridChange, timePeriodAsString has not changed: " + timePeriodAsString);
    }
    

// ​event: 
// value: Date Wed May 22 2024 13:00:00 GMT+0200 (Central European Summer Time)
// valueText: "13:00"
  }

  updateTimeGrid(pw:any = this.pw) {
    if (!pw.productObj.useTimegrid) return
  
    let tg = this.timegrid;

    let ti = pw.timeItemsAvailable && pw.timeItemsAvailable[0];

    let firstAvailable = ti;

    if (ti) {
      for (let item of ti.items || []) {
        if (item.available > 0) {
          firstAvailable = item;
          break;
        }
      }
  
    }

    let start = this.bas.ui.dateParseIso(pw.startAsIso8601);
    let end = this.bas.ui.dateParseIso(pw.endAsIso8601);
  

    if (firstAvailable) {
      let faStart = this.bas.ui.dateParse(firstAvailable.startAsString);
      let pwStart = this.bas.ui.dateParse(pw.startAsString);

      if(this.bas.envtest) console.log("updateTimeGrid, faStart: " + faStart + "; pwStart: " + pwStart);

      if (pwStart.getTime() < faStart.getTime() ) {
        start = faStart;
        end =  this.bas.ui.dateParse(firstAvailable.endAsString);
      }

    }

    tg.start = start;
    tg.end = end;


    // if (pw.isAvailable) {

    //   tg.start = this.bas.ui.dateParseIso(pw.startAsIso8601);
    //   tg.end =  this.bas.ui.dateParseIso(pw.endAsIso8601);
    // } else {
    //   for (let item of ti.items || []) {
    //     if (item.available > 0) {
    //       tg.start = this.bas.ui.dateParse(item.startAsString);
    //       tg.end =  this.bas.ui.dateParse(item.endAsString);
    //       break;
    //     }
    //   }
    // }

    tg.startTime = this.bas.ui.timeFormat(tg.start);
    tg.endTime = this.bas.ui.timeFormat(tg.end);

    if(this.bas.envtest) console.log("updateTimeGrid, tg.start: "+tg.start+", pw: ",pw,", tg: ", tg);

    this.onTimegridChange('start', { valueText: tg.startTime  })
    

  }

  dateOnPageLoading(event:any) {
    // if(this.bas.envtest) console.log("dateOnPageLoading, ", event, ", invalid: ", this.dateInvalid);
    this.dateUpdateInvalid(event);
  }

  dateOnChange(event:any) {
    if(this.bas.envtest) console.log("dateOnChange, startDateAsString: " + this.pw.startDateAsString
      + ", startDateAsJsDate: " + this.pw.startDateAsJsDate + ", event: ", event);
    this.pw.startDateAsString = this.bas.ui.dateToString(this.pw.startDateAsJsDate);
    // this.postChange();
    this.change.emit({ field: "startDateAsString", value:  this.pw.startDateAsString  });
  }

  dateRangeOnChange(event:any) {
    if(this.bas.envtest) console.log("dateOnChange, dateRangeOnChange: ", event)
    let dates:Date[] = event.value;
    if (dates === undefined || dates.length != 2 || dates[0] == null || dates[1] == null) return;
    this.pw.periodAsRange =  MiscUtil.datesToDateRange(event.value);
    this.change.emit({ field: "periodAsRange", value:   this.pw.periodAsRange });
  }

  onPeriodChange(event:any) {
    if(this.bas.envtest) console.log("onPeriodChange, event: ", event);
    this.change.emit({ field: "productPeriodAsString", value:   this.pw.productPeriodAsString });

  }

  onTimeChange(event:any) {
    if(this.bas.envtest) console.log("onTimeChange, event: ", event);
    this.change.emit({ field: "timePeriodAsString", value:   this.pw.timePeriodAsString });
  
  }

  

  onChange(event:any, field:string) {
    if(this.bas.envtest) console.log("onChange, event: ", event);


    this.change.emit({ field: field, value:   this.pw[field] });

  }

  dateUpdateInvalid(event:any) {
    let inst = event.inst;
    let fd = event.firstDay;
    let ld = event.lastDay;


    let colors:any[] = [];
    let invalid:any[] = [];
    // {
    //   date: fbo.date,
    //   highlight: '#537b53'
    // } 
    
    let pw = this.pw;

    let dates:Date[] = this.bas.ui.getAllDatesBwtweenDates(fd, ld);

    // <c:if test="${wpd.company.username == 'skaperverkettonsberg'}"> <!-- TODO:hack -->
		// 	extraCalendarOptions = { daysOfWeekDisabled: [ 0,6 ]};
		// 	<c:if test="${fn:contains(p.name, 'Revetal')}">
		// 	  extraCalendarOptions = { daysOfWeekDisabled: [ 0 ]};
		// 	</c:if>
		// </c:if>


    let today = MiscUtil.getDateAsInt(new Date());
    let isAdmin = this.bas.aas.isAdminProvider();
    

    
    // let isSkaperverkettonsberg = this.bas.ds.findCid('pw-period-guests.dateUpdateInvalid') === "skaperverkettonsberg";

    for (let current of dates) {
      let dateInt = MiscUtil.getDateAsInt(current);
      if ( dateInt < today) {
        invalid.push(current);
        if (isAdmin && false) colors.push({ date: current, background: 'rgba(237, 28, 36, 0.5)' })
        continue;
      } 

      // if (isSkaperverkettonsberg) { // TODO: hack, dette burde gjøres annerledes
      //   let dow = current.getDay();
      //   if (dow === 0 || (dow === 6 && pw.productObj.mkName.indexOf("Revetal") < 0)) {
      //     invalid.push(current);
      //   }
      // }
   
      
      if (pw.selectableDatesNext && pw.selectableDatesNext.length) {
        if (MiscUtil.binarySearch(pw.selectableDatesNext, dateInt) < 0) {
          //console.log("current: " + current + " -> dateInt: " + dateInt + ", binarySearch: " + MiscUtil.binarySearch(pw.selectableDatesNext, dateInt) + ", pw: ", pw);

          invalid.push(current);

          if (isAdmin) colors.push({ date: current, background: 'rgba(237, 28, 36, 0.5)' })

        }
      } else if (((pw.selectableDates && pw.selectableDates.length) || pw.productObj.timeProduct)) {
        if (MiscUtil.binarySearch(pw.selectableDates, dateInt) < 0) {
          invalid.push(current);
          if (isAdmin) colors.push({ date: current, background: 'rgba(237, 28, 36, 0.5)' })
        }
      } else if (pw.productObj.periodProduct && pw.occupiedDates) {
        if (MiscUtil.binarySearch(pw.occupiedDates, dateInt) >= 0) {
          invalid.push(current);
          if (isAdmin) colors.push({ date: current, background: 'rgba(237, 28, 36, 0.5)' })
        }
      }
    }


    // if (isSkaperverkettonsberg) { // TODO: hack, dette burde gjøres annerledes
    //   invalid.push({
    //     recurring: {
    //       repeat: 'weekly',
    //       weekDays: (pw.productObj.mkName.indexOf("Revetal") < 0 ? 'SA,' : '' )+ 'SU'
    //     }
    //   })
    // }

    //console.log("Setting dateInvalid: " + this.dateInvalid.length + " -> " + invalid.length);
    if (!isAdmin) this.dateInvalid = invalid;
    this.dateColors = colors;



  }

 
  onGuestsUpdate(event:any) {
    if(this.bas.envtest) console.log("onGuestsUpdate, event: ", event);
    this.change.emit({ field: "guestCounts", value: event.value });
  }

}



