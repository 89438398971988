import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


@Component({
  selector: 'btlib-iframe',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './iframe.component.html',
  styleUrl: './iframe.component.scss'
})
export class IframeComponent {
  bas = inject(BooktechAppService);

  @Input() src:string = "";
  @Input() autoResize:boolean = true;

  safeSource:SafeResourceUrl|undefined;

  constructor() {

  }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.safeSource = this.bas.ui.trustUrl(this.src);
  }
  

}

