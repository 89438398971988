<nz-alert
                    
  [nzType]="nzType"
  [nzMessage]="messageTmpl"
  [nzDescription]="description ? descTmpl : null"
  nzShowIcon
  [class]="btClass"
></nz-alert>
<ng-template #messageTmpl>
  <div [innerHTML]="message"></div>
</ng-template>
<ng-template #descTmpl>
  <div [innerHTML]="description"></div>
</ng-template>

