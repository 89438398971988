<div *ngIf="pw">

  <nz-drawer
    nzWrapClassName="drawer-responsive"
    [nzClosable]="true"
    [nzVisible]="pw"
    [nzPlacement]="bas.ui.r.ltesm ? 'top' : 'bottom'"
    [nzTitle]="('common._order' | actrans | str) + ' ' + pw.productObj.mkName"
    [nzFooter]="footerTpl"
    [nzHeight]="bas.ui.r.ltesm ? '90%' : '50%'"
    (nzOnClose)="drawerClose()"
  >
    <ng-container *nzDrawerContent >

      <ng-container [ngSwitch]="true">
        <div *ngSwitchCase="pw.productObj.customProductView !== undefined">
          <ng-container [ngSwitch]="pw.productObj.customProductView">
            <!-- <div *ngSwitchCase="'order-form-route'">
              <app-order-form-route></app-order-form-route>
            </div> -->
          </ng-container>

        </div>
        <div *ngSwitchDefault>
  


          <div>
            <btlib-pw-period-guests [pw]="pw" [opts]="{viewName: 'fullWidth'}" (change)="onPeriodGuestsUpdate($event)"></btlib-pw-period-guests>
      
          </div>

          <div class="mt-d"></div>

          @if (pw.findTimeArea) {
       
            <nz-alert class="mt-8" nzType="info" [nzMessage]="('common.venue' | actrans | str) + ': ' + pw.findTimeArea.mkName " [nzDescription]="venueTmpl" nzShowIcon></nz-alert>
            <ng-template #venueTmpl>
    
              <div [innerHTML]="pw.findTimeArea.mkDesc"></div>
            </ng-template>
    
          }
    
          <div class="mt-d"></div>

          <div *ngIf="pw.errorMsg">
            <bt-alert  nzType="error" [mk]="pw.errorMsg" ></bt-alert>
            <!-- <nz-alert
              nzType="error"
              [nzMessage]="errorTmpl"
              nzShowIcon
            ></nz-alert>
            <ng-template #errorTmpl>
              <div [innerHTML]="pw.errorMsg | actrans "></div>
            </ng-template> -->
    
          </div>

          <div *ngIf="!pw.errorMsg">



            <nz-alert
              *ngIf="pw.productObj.providerConfirmationRequired"
              class="mb-d"
              nzType="info"
              [nzMessage]="providerConfirmationRequiredMessage"
              nzShowIcon
            ></nz-alert>
            <ng-template #providerConfirmationRequiredMessage>
              <p [innerHTML]="'web.order.productInfo.providerConfirmationRequired' | actrans"></p>
            </ng-template>
      

            <div
              *ngIf="pw.availablePoolUnits"
              >
              <!-- TODO: flytte til app-pw-period-guests? -->
              <form nz-form [nzLayout]="'vertical'">

                
                <div nz-row  [nzGutter]="bas.ui.gridMd.gutter" nzAlign="top" >
                  <div nz-col 
                    [nzXs]="24" 
                    [nzSm]="8" 
                    [nzMd]="8" 
                    >

                    <nz-form-item>
                      <nz-form-label  class="" nzFor="">{{ 'web.main.order.selectPoolUnit' | actrans }}</nz-form-label>
                      <nz-form-control [nzErrorTip]="'web.main.order.selectPoolUnit' | actrans | str">
                  
                        <nz-input-group nzPrefixIcon="home" style="padding: 3px 10px;">
            
                          <nz-select 
                          [(ngModel)]="pw.jsonData.poolUnitId"
                          [ngModelOptions]="{standalone: true}"
                          (ngModelChange)="onPoolUnitChange($event)"
                          >
                            <nz-option 
                              [nzValue]="''" 
                              [nzLabel]="'----- ' + ('web.main.order.selectPoolUnit.empty' | actrans) + ' ----'">
                            </nz-option>
                            <nz-option 
                              *ngFor="let apu of pw.availablePoolUnits || []" 
                              [nzValue]="apu.id" 
                              [nzLabel]="apu.mkName + (apu.amountAsDouble && apu.amountAsDouble != pw.amountDouble ? ': kr ' + (apu.amountAsDouble | nf) : '')">
                            </nz-option>
                          </nz-select>
                    
                        </nz-input-group>
              
                      </nz-form-control>
                    </nz-form-item>

                  </div>
                </div>
            
              </form>
            </div>
      
            <div class="bt-table">
              <table>
                <tbody>
            
                <tr *ngIf="pw.productObj.tcDate && !pw.productObj.singleDateProduct ">
                  <td>
                    <span *ngIf="bas.ui.r.gtemd">{{ pw.productObj.mkName }}, </span>
                    {{ pw.days }} {{ 'common.days' | actrans | str | lowercase  }}
                  </td>
                  <td class="text-right" [style.width]="bas.ui.r.gtesm ? '240px' : '50%'">
                    <span *ngIf="pw.amountDouble">
                      {{ pw.amountDouble | nf }}
                    </span>
                    <span *ngIf="!pw.amountDouble">
                      {{ pw.periodString }}
                    </span>
                  </td>
                </tr>
                <tr *ngIf="pw.productObj.tcTime || (pw.productObj.tcDate && pw.productObj.singleDateProduct)">
                  <td>
                    {{ 'common.count' | actrans }}: {{ pw.quantity }} 
                  </td>
                  <td  class="text-right" [style.width]="bas.ui.r.gtesm ? '240px' : '50%'">
                    <span *ngIf="pw.amountDouble">
                      {{ pw.amountDouble | nf }}
                    </span>
                    <span *ngIf="!pw.amountDouble">
                      {{ pw.periodString }}
                    </span>
                  </td>
                </tr>
              </tbody>
        
            </table>
          </div>
          
      
          <div *ngIf="pw.groupItems?.length">
            <btlib-cart-add-item *ngFor="let gi of  pw.groupItems" [pwp]="pw" [pw]="gi" [opts]="{type: 'groupItem'}" (change)="onItemChange($event, gi)" ></btlib-cart-add-item>
          </div>
      
          <div *ngIf="pw.packageItems?.length && pw.productObj.showChildItems">
            <!-- <div *ngFor="let pi of pw.packageItems">{{ pi.productObj.mkName }}</div> -->
            <btlib-cart-add-item *ngFor="let pi of  pw.packageItems" [pwp]="pw" [pw]="pi" [opts]="{type: 'packageItem'}" (change)="onItemChange($event, pi)" ></btlib-cart-add-item>
        
          </div>
      
          <div class="bt-table">
            <table>
  
      
              <tbody *ngIf="pw.accessoriesMandatory.length">
                <tr *ngFor="let acc of pw.accessoriesMandatory">
                  <td class="btt-label">
                    {{ acc.findName }}
                  </td>
                  <td  class="text-right">
                    {{ acc.amountDouble | nf }}
                  </td>
                </tr>
          
                <tr >
                  <td class="btt-label">
                    {{ 'common.sum' | actrans }}
                  </td>
                  <td class="text-right">
                    {{ pw.totalAmountDouble | nf }}
                  </td>
                </tr>
              </tbody>
      
              
              </table>
            </div>
      
            <div class="mt-8" *ngIf="pw.accessoriesOptional.length">
              <div >
                <h2>{{ 'app.cbsite.common.order.accessoriesOptional' | actrans }}</h2>
              </div>
      
              <btlib-cart-add-item *ngFor="let acc of  pw.accessoriesOptional" [pwp]="pw" [pw]="acc" [opts]="{type: 'accessory'}" (change)="onItemChange($event, acc)" ></btlib-cart-add-item>
        
      
              
            </div>
      
      
            <hr/>
            <div class="bt-table">
              <table>
                <tbody>
                  <tr>
                    <td class="btt-label">
                      {{ 'common.total' | actrans }}
                    </td>
                    <td class="text-right" [style.width]="bas.ui.r.gtesm ? '240px' : '50%'">
                      {{ pw.calcAmount | nf }}
                    </td>
                  </tr>
                </tbody>
            
              </table>
            </div>
      
            <div class="mt-8" *ngIf="pw.inputValuesList?.length && inputValues.obj">
              <!-- <h2>{{ 'common.info' }}</h2> -->
      
              <!-- <div nz-row>
                <div nz-col ></div>
              </div> -->
              <form nz-form [formGroup]="inputValues.form"  class="item-form " nzLayout="vertical" [nzAutoTips]="bas.fs.autoTips">
                
                <!-- <div *ngFor="let iv of pw.inputValuesList">
                  <bt-dynamic-form-control  [control]="inputValues.controls[iv.extKey]" [form]="inputValues.form" [options]="{}"></bt-dynamic-form-control>
      
                </div> -->
                <btlib-form-row [controls]="inputValues.list | filterBy: { data: { isGuestInput: false }}" [form]="inputValues.form"  [grid]="bas.ui.gridMd"  ></btlib-form-row>
                <btlib-form-row [controls]="inputValues.list | filterBy: { data: { isGuestInput: true }}" [form]="inputValues.form"  [grid]="bas.ui.gridMd"  ></btlib-form-row>
                  
              </form>
      
            </div>
      
      
            <div>
              <!--
                TODO: relaterte produkter, gjerne som kan legges rett i handlevognen?
              -->
            </div>

          </div>
        </div>
      </ng-container>

     

    </ng-container>

    <ng-template #footerTpl>
      <div style="float: right">
        <button *ngIf="bas.ui.r.gtemd" nz-button (click)="drawerClose()">{{ 'common.cancel' | actrans }}</button>

        <button class="ml-d"
        nz-button 
        nzType="primary" (click)="addToCart()"
        [disabled]="!!pw.errorMsg"
        >
        {{ 'web.cart.add' | actrans }}
      </button>

      <button  class="ml-d"
        nz-button 
        nzType="primary" (click)="checkout()"
        [disabled]="!!pw.errorMsg"
        >
        {{ 'web.cart.checkout' | actrans }}
      </button>
      </div>
    </ng-template>
  </nz-drawer>

</div>
